import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
	children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
	render() {
		// eslint-disable-next-line
		const { children, ...attributes } = this.props;

		return (
			<React.Fragment>
				<span>
					<a href="#">JBL</a> &copy; 2020
				</span>
			</React.Fragment>
		);
	}
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;

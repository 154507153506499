import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var config = {
	apiKey: "AIzaSyCpoTzlfFUdxVUFhtavtEiRAPJoYnh2Rsc",
	authDomain: "jbl-website.firebaseapp.com",
	databaseURL: "https://jbl-website.firebaseio.com",
	projectId: "jbl-website",
	storageBucket: "jbl-website.appspot.com",
	messagingSenderId: "326660834466",
	appId: "1:326660834466:web:43d00b66bc8c5a6835c3fb",
	measurementId: "G-EVJPZG4RDL",
};

firebase.initializeApp(config);

export default firebase;

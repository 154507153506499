export default {
	items: [
		{
			name: "JBL CMS",
			url: "/dashboard",
			icon: "icon-cc",
		},
		{
			title: true,
			name: "Editar Contenido",
			wrapper: {
				element: "", // required valid HTML5 element tag
				attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
			},
			class: "", // optional class names space delimited list for title item ex: "text-center"
		},
		{
			name: "Home",
			url: "/edit-content/home",
			icon: "icon-home",
			description: "Edit home page content. Change text, images and video.",
			navigationFor: "edit-content",
			children: [
				{
					name: "Slider",
					url: "/edit-content/home/slider",
					icon: "cil-airplay",
					description: "Edit landing section content. Change text and video.",
				},
				{
					name: "Nuestras diferencias",
					url: "/edit-content/home/our-differences",
					icon: "icon-rocket",
					description: "Edit our differences section.",
				},
				{
					name: "Aliados",
					url: "/edit-content/home/allies",
					icon: "icon-organization",
					description: "Edit our allies section.",
				},
				{
					name: "Comentarios de clientes",
					url: "/edit-content/home/clients-comments",
					icon: "icon-trophy",
					description: "Edit clients comments section.",
				},
				{
					name: "Que seguro necesitas?",
					url: "/edit-content/home/your-needs",
					icon: "cil-diamond",
					description: "Edit get what you need section.",
				},
				{
					name: "Opiniones de expertos",
					url: "/edit-content/home/experts",
					icon: "icon-organization",
					description:
						"Edit experts opinions section content. Change text and images.",
				},
				{
					name: "Contacto",
					url: "/edit-content/home/contact-home",
					icon: "cil-inbox",
					description: "Edit contact section content. Change text and images.",
				},
			],
		},
		{
			name: "Our Products",
			url: "/edit-content/our-products",
			icon: "icon-layers",
			description: "Edit our products pages content. Change text and images.",
			navigationFor: "edit-content",
			children: [
				{
					name: "Personas",
					url: "/edit-content/our-products/persons",
					icon: "cil-contact",
					description: "Editar contenido de la pagina 'Seguro de personas'.",
				},
				{
					name: "Automoviles",
					url: "/edit-content/our-products/vehicles",
					icon: "cil-car-alt",
					description: "Editar contenido de la pagina 'Seguro de vehiculos'.",
				},
				{
					name: "Patrimoniales",
					url: "/edit-content/our-products/whealth",
					icon: "cil-diamond",
					description: "Editar contenido de la pagina 'Seguros patrimoniales'.",
				},
				{
					name: "Fianzas",
					url: "/edit-content/our-products/bonds",
					icon: "cil-institution",
					description: "Editar contenido de la pagina 'Fianzas'.",
				},
				{
					name: "Imagenes para el Slider",
					url: "/edit-content/our-products/slider-images",
					icon: "cil-airplay",
					description:
						"Editar imagenes utilizadas para el slider de paginas 'Nuestros productos'.",
				},
			],
		},
		{
			name: "Experiencia JBL",
			url: "/edit-content/jbl-experience",
			icon: "cil-factory",
			description: "Edit our products pages content. Change text and images.",
			navigationFor: "edit-content",
		},
		{
			name: "Contacto/Siniestros",
			url: "/edit-content/contact",
			icon: "cil-envelope-open",
			description: "Edit our products pages content. Change text and images.",
			navigationFor: "edit-content",
		},
		{
			name: "Formularios",
			url: "/edit-content/forms",
			icon: "cil-file",
			description: "Editar contenido de formularios.",
			navigationFor: "edit-content",
		},
		{
			name: "Footer",
			url: "/edit-content/footer",
			icon: "cil-settings",
			description: "Edit our products pages content. Change text and images.",
			navigationFor: "edit-content",
		},
		{
			name: "Banco de iconos",
			url: "/edit-content/icons",
			icon: "cil-color-palette",
			description: "Iconos utilizados para los formularios.",
			navigationFor: "edit-content",
		},
		{
			name: "Redes sociales",
			url: "/edit-content/socials",
			icon: "cil-voice-over-record",
			description: "Links a reder.",
			navigationFor: "edit-content",
		},
		{
			title: true,
			name: "Formularios",
			wrapper: {
				// optional wrapper object
				element: "", // required valid HTML5 element tag
				attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
			},
			class: "", // optional class names space delimited list for title item ex: "text-center"
		},
		{
			name: "Mensajes de formularios",
			url: "/formsSubmitions",
			icon: "icon-people",
			description: "Ver todos los mensajes de formularios.",
			navigationFor: "Formularios",
		},
		// {
		// 	name: "Further Information",
		// 	url: "/edit-content/further-information",
		// 	icon: "cil-info",
		// 	description:
		// 		"Edit further information page content. Change text and images.",
		// 	navigationFor: "edit-content",
		// 	children: [
		// 		{
		// 			name: "Landing",
		// 			url: "/edit-content/further-information/landing",
		// 			icon: "cil-airplay",
		// 			description: "Edit landing section content. Change text and image.",
		// 		},
		// 		{
		// 			name: "Information items",
		// 			url: "/edit-content/further-information/information-items",
		// 			icon: "icon-grid",
		// 			description:
		// 				"Edit information items section content. Change text and images.",
		// 		},
		// 		{
		// 			name: "Get Involved",
		// 			url: "/edit-content/further-information/get-involved",
		// 			icon: "cil-inbox",
		// 			description:
		// 				"Edit get involved section content. Change text and images.",
		// 		},
		// 	],
		// },
		// {
		// 	name: "Contact",
		// 	url: "/edit-content/contact",
		// 	icon: "icon-envelope",
		// 	navigationFor: "edit-content",
		// 	description: "Edit contact page content. Form titles and options.",
		// },
		// {
		// 	name: "Footer",
		// 	url: "/edit-content/footer",
		// 	icon: "cil-expand-down",
		// 	navigationFor: "edit-content",
		// 	description: "Edit footer section content. Change contact information.",
		// },
		// {
		//   divider: true,
		// },

		// {
		//   title: true,
		//   name: 'Pod Updates',
		//   wrapper: {            // optional wrapper object
		//     element: '',        // required valid HTML5 element tag
		//     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
		//   },
		//   class: ''             // optional class names space delimited list for title item ex: "text-center"
		// },
		// {
		//   name: 'Pod Summary',
		//   url: '/pod-summary',
		//   icon: 'icon-list',
		//   description: 'Manage pod updates. Add, edit or delete pod updates',
		//   navigationFor: 'pod'
		// },
		// {
		//   title: true,
		//   name: 'Clients',
		//   wrapper: {            // optional wrapper object
		//     element: '',        // required valid HTML5 element tag
		//     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
		//   },
		//   class: ''             // optional class names space delimited list for title item ex: "text-center"
		// },
		// {
		//   name: 'Approved Clients',
		//   url: '/approved-clients',
		//   icon: 'icon-user-following',
		//   description: 'Manage pod updates. Add, edit or delete pod updates',
		//   navigationFor: 'clients'
		// },
		//   {
		//     divider: true,
		//     class: 'm-2'
		//   },
		//   {
		//     title: true,
		//     name: 'Labels'
		//   },
		//   {
		//     name: 'Label danger',
		//     url: '',
		//     icon: 'fa fa-circle',
		//     label: {
		//       variant: 'danger'
		//     },
		//   },
		//   {
		//     name: 'Label info',
		//     url: '',
		//     icon: 'fa fa-circle',
		//     label: {
		//       variant: 'info'
		//     }
		//   },
		//   {
		//     name: 'Label warning',
		//     url: '',
		//     icon: 'fa fa-circle',
		//     label: {
		//       variant: 'warning'
		//     }
		//   },
		{
			name: "Go to JBL",
			url: "https://jbl-website.web.app/",
			icon: "icon-cc",
			variant: "custom",
			class: "mt-auto",
			attributes: {
				target: "_blank",
				rel: "noopener",
			},
		},
	],
};

// import React from 'react';
import Loadable from "react-loadable";
import DefaultLayout from "../containers/DefaultLayout/DefaultLayout";
import Login from "../views/Login/Login";
import Loading from "../components/Loading/Loading";

const Dashboard = Loadable({
	loader: () => import("../views/Dashboard/Dashboard"),
	loading: Loading,
});

const EditContent = Loadable({
	loader: () => import("../views/EditContent/EditContent"),
	loading: Loading,
});

const EditHome = Loadable({
	loader: () => import("../views/EditContent/EditHome/EditHome"),
	loading: Loading,
});

const EditSlider = Loadable({
	loader: () => import("../views/EditContent/EditHome/EditSlider/EditSlider"),
	loading: Loading,
});

const EditDifferences = Loadable({
	loader: () =>
		import("../views/EditContent/EditHome/EditOurDifferences/EditDifferences"),
	loading: Loading,
});

const EditAllies = Loadable({
	loader: () => import("../views/EditContent/EditHome/EditAllies/EditAllies"),
	loading: Loading,
});

const EditClientComments = Loadable({
	loader: () =>
		import(
			"../views/EditContent/EditHome/EditClientComments/EditClientComments"
		),
	loading: Loading,
});

const EditYouNeed = Loadable({
	loader: () => import("../views/EditContent/EditHome/EditYouNeed/EditYouNeed"),
	loading: Loading,
});

const EditExpertOpinions = Loadable({
	loader: () =>
		import(
			"../views/EditContent/EditHome/EditExpertOpinions/EditExpertOpinions"
		),
	loading: Loading,
});

const EditContactSection = Loadable({
	loader: () => import("../views/EditContent/EditHome/EditContact/EditContact"),
	loading: Loading,
});

const EditOurProducts = Loadable({
	loader: () => import("../views/EditContent/EditOurProducts/EditOurProducts"),
	loading: Loading,
});

const EditPersons = Loadable({
	loader: () =>
		import("../views/EditContent/EditOurProducts/EditPersons/EditPersons"),
	loading: Loading,
});
const EditCars = Loadable({
	loader: () =>
		import("../views/EditContent/EditOurProducts/EditCars/EditCars"),
	loading: Loading,
});
const EditWhealth = Loadable({
	loader: () =>
		import("../views/EditContent/EditOurProducts/EditWhealth/EditWhealth"),
	loading: Loading,
});
const EditBonds = Loadable({
	loader: () =>
		import("../views/EditContent/EditOurProducts/EditBonds/EditBonds"),
	loading: Loading,
});
const EditSliderImages = Loadable({
	loader: () =>
		import(
			"../views/EditContent/EditOurProducts/EditSliderImages/EditSliderImages"
		),
	loading: Loading,
});
const EditContact = Loadable({
	loader: () => import("../views/EditContent/EditContact/EditContact"),
	loading: Loading,
});
const EditJblExperience = Loadable({
	loader: () =>
		import("../views/EditContent/EditJblExperience/EditJblExperience"),
	loading: Loading,
});
const EditFooter = Loadable({
	loader: () => import("../views/EditContent/EditFooter/EditFooter"),
	loading: Loading,
});
const EditIcons = Loadable({
	loader: () => import("../views/EditContent/EditIcons/EditIcons"),
	loading: Loading,
});
const EditSocials = Loadable({
	loader: () => import("../views/EditContent/EditSocials/EditSocials"),
	loading: Loading,
});
const EditInsuranceForms = Loadable({
	loader: () =>
		import("../views/EditContent/EditInsuranceForms/EditInsuranceForms"),
	loading: Loading,
});

const FormsSubmitions = Loadable({
	loader: () => import("../views/formsSubmitions/formsSubmitions"),
	loading: Loading,
});

// const EditContact = Loadable({
// 	loader: () => import("../views/EditContent/EditContact/EditContact"),
// 	loading: Loading,
// });

// const EditFurtherInformation = Loadable({
// 	loader: () =>
// 		import(
// 			"../views/EditContent/EditFurtherInformation/EditFurtherInformation"
// 		),
// 	loading: Loading,
// });

// const EditLandingFurtherInformation = Loadable({
// 	loader: () =>
// 		import(
// 			"../views/EditContent/EditFurtherInformation/EditLanding/EditLandingFurtherInformation"
// 		),
// 	loading: Loading,
// });

// const EditInformationItems = Loadable({
// 	loader: () =>
// 		import(
// 			"../views/EditContent/EditFurtherInformation/EditInformationItems/EditInformationItems"
// 		),
// 	loading: Loading,
// });

// const EditGetInvolvedFurtherInformation = Loadable({
// 	loader: () =>
// 		import(
// 			"../views/EditContent/EditFurtherInformation/EditGetInvolved/EditGetInvolvedFurtherInformation"
// 		),
// 	loading: Loading,
// });

// const EditFooter = Loadable({
// 	loader: () => import("../views/EditContent/EditFooter/EditFooter"),
// 	loading: Loading,
// });

// const PodSummary = Loadable({
// 	loader: () => import("../views/PodSummary/PodSummary"),
// 	loading: Loading,
// });

// const ApprovedClients = Loadable({
// 	loader: () => import("../views/Clients/ApprovedClients"),
// 	loading: Loading,
// });

const routes = [
	{ path: "/", name: "Home", component: DefaultLayout, exact: true },
	{ path: "/login", name: "Login", component: Login, exact: true },
	{ path: "/dashboard", name: "Dashboard", component: Dashboard },
	{
		path: "/edit-content",
		name: "Edit Content",
		component: EditContent,
		exact: true,
	},
	// Home
	{
		path: "/edit-content/home",
		name: "Home",
		component: EditHome,
		exact: true,
	},
	{
		path: "/edit-content/home/slider",
		name: "slider",
		component: EditSlider,
	},
	{
		path: "/edit-content/home/our-differences",
		name: "Our differences",
		component: EditDifferences,
	},
	{
		path: "/edit-content/home/allies",
		name: "Allies Section",
		component: EditAllies,
	},
	{
		path: "/edit-content/home/clients-comments",
		name: "Clients comments Section",
		component: EditClientComments,
	},
	{
		path: "/edit-content/home/your-needs",
		name: "What you need",
		component: EditYouNeed,
	},
	{
		path: "/edit-content/home/experts",
		name: "Experts opinions",
		component: EditExpertOpinions,
	},
	{
		path: "/edit-content/home/contact-home",
		name: "Contact Section Home",
		component: EditContactSection,
	},
	// Nuestros productos
	{
		path: "/edit-content/our-products",
		name: "Our Products",
		component: EditOurProducts,
		exact: true,
	},
	{
		path: "/edit-content/our-products/persons",
		name: "Personal insurance",
		component: EditPersons,
		exact: true,
	},
	{
		path: "/edit-content/our-products/vehicles",
		name: "Vehicles insurance",
		component: EditCars,
		exact: true,
	},
	{
		path: "/edit-content/our-products/whealth",
		name: "Whealth insurance",
		component: EditWhealth,
		exact: true,
	},
	{
		path: "/edit-content/our-products/bonds",
		name: "Bonds insurance",
		component: EditBonds,
		exact: true,
	},
	{
		path: "/edit-content/our-products/slider-images",
		name: "Slider images",
		component: EditSliderImages,
		exact: true,
	},
	{
		path: "/edit-content/contact",
		name: "Contact Page",
		component: EditContact,
		exact: true,
	},
	{
		path: "/edit-content/jbl-experience",
		name: "Experience JBL Page",
		component: EditJblExperience,
		exact: true,
	},
	{
		path: "/edit-content/footer",
		name: "Footer Content",
		component: EditFooter,
		exact: true,
	},
	{
		path: "/edit-content/forms",
		name: "Contenido de formularios",
		component: EditInsuranceForms,
		exact: true,
	},
	{
		path: "/edit-content/icons",
		name: "Iconos para CMS",
		component: EditIcons,
		exact: true,
	},
	{
		path: "/edit-content/socials",
		name: "Redes Sociales",
		component: EditSocials,
		exact: true,
	},
	{
		path: "/formsSubmitions",
		name: "Formularios",
		component: FormsSubmitions,
		exact: true,
	},

	// {
	// 	path: "/edit-content/further-information",
	// 	name: "Further Information",
	// 	component: EditFurtherInformation,
	// 	exact: true,
	// },
	// {
	// 	path: "/edit-content/further-information/landing",
	// 	name: "Landing",
	// 	component: EditLandingFurtherInformation,
	// },
	// {
	// 	path: "/edit-content/further-information/information-items",
	// 	name: "Information Items",
	// 	component: EditInformationItems,
	// },
	// {
	// 	path: "/edit-content/further-information/get-involved",
	// 	name: "Get Involved",
	// 	component: EditGetInvolvedFurtherInformation,
	// },
	// { path: "/edit-content/contact", name: "Contact", component: EditContact },
	// { path: "/edit-content/footer", name: "Footer", component: EditFooter },
	// {
	// 	path: "/prospects-management",
	// 	name: "Prospects Managemet",
	// 	component: ProspectsManagement,
	// },
	// { path: "/pod-summary", name: "Pod Summary", component: PodSummary },
	// {
	// 	path: "/approved-clients",
	// 	name: "Approved Clients",
	// 	component: ApprovedClients,
	// },
];

export default routes;
